import {
  LoginCredentials,
  LoginGoogleCredentials,
  LoginFacebookCredentials,
  UserResponse,
  RegisterCredentials,
  ForgotPasswordCredentials,
  RegisterUserResponse,
  ResendEmailCredentials,
  ConfirmInviteCredentials,
} from './types';

import axios from '@/lib/axios';

export const loginWithEmailAndPassword = (data: LoginCredentials): Promise<UserResponse> => {
  return axios.unauthorized({}).post('/login', data);
};

export const loginWithGoogle = (data: LoginGoogleCredentials): Promise<UserResponse> => {
  return axios.unauthorized({}).post('/login/gmail', data);
};

export const loginWithFacebook = (data: LoginFacebookCredentials): Promise<UserResponse> => {
  return axios.unauthorized({}).post('/login/facebook', data);
};

export const refetchUserWithId = (id: string): Promise<User> => {
  return axios.authorized({}).get(`/users/${id}`);
};

export const registerWithEmailAndPassword = (
  data: RegisterCredentials,
): Promise<RegisterUserResponse> => {
  return axios.unauthorized({}).post('/users', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const forgotPassword = (data: ForgotPasswordCredentials): Promise<any> => {
  return axios.unauthorized({}).post('forgot-password', data);
};

export const resendEmailConfirmation = (data: ResendEmailCredentials) => {
  return axios.unauthorized({}).post('resend-email-confirmation', data);
};

export const ConfirmInviteRequest = (data: ConfirmInviteCredentials) => {
  let type: string | null = '';

  switch (data.type) {
    case 'evento':
      type = 'event';
      break;
    default:
      type = data.type;
      break;
  }

  return axios.authorized({}).patch(`/${type}-managers/${data.requestId}/confirm-invite`, null, {
    headers: { 'invite-token': data.token },
  });
};
