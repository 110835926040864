import { mode, transparentize } from '@chakra-ui/theme-tools';

const customVariant = ({
  bg,
  bgHover = bg,
  bgGradientHover = bg,
  bgActive = bgHover,
  bgGradientActive = bgHover,
  color,
  colorHover = color,
  colorActive = color,
  border,
  boxShadowFocus = 'outline',
  ...rest
}: any) => {
  return {
    bg,
    color: color,
    _focus: {
      boxShadow: boxShadowFocus,
    },
    _hover: {
      bg: bgHover,
      bgGradient: bgGradientHover,
      color: colorHover,
      _disabled: {
        bg,
      },
    },
    borderColor: border?.color,
    border: border?.size,
    _active: {
      bg: bgActive,
      bgGradient: bgGradientActive,
      color: colorActive,
    },
    px: 12,
    ...rest,
  };
};

export default {
  variants: {
    // Custom variants
    '@primary': (props) =>
      customVariant({
        bg: mode('brand.500', 'brand.300')(props),
        bgHover: mode('brand.600', 'brand.400')(props),
        bgActive: mode('brand.700', 'brand.500')(props),
        color: mode('white', 'brand.900')(props),
        boxShadowFocus: 'outline-brand',
      }),
    '@navbar': (props) =>
      customVariant({
        bg: mode('primaryNavbarButton', 'brand.300')(props),
        bgHover: mode('primaryNavbarButton', 'brand.400')(props),
        bgActive: mode('brand.700', 'brand.500')(props),
        color: mode('white', 'brand.900')(props),
        boxShadowFocus: 'outline-brand',
        border: '1px solid white',
      }),
    '@primaryLight': () =>
      customVariant({
        bg: 'brand.300',
        bgHover: 'brand.400',
        bgActive: 'brand.500',
        color: 'white',
        boxShadowFocus: 'outline-brand',
      }),
    '@primaryGradient': (props) =>
      customVariant({
        bgGradient: 'linear(to-l, brand.300, brandlight)',
        bgGradientHover: 'linear(to-l, brand.400, brandlight)',
        bgGradientActive: 'linear(to-l, brand.500, brandlight)',
        color: mode('white', 'brand.900')(props),
        boxShadowFocus: 'outline-brand',
      }),
    '@primaryOutlined': (props) =>
      customVariant({
        bg: mode('brand.500', 'brand.300')(props),
        bgGradientHover: mode('brand.600', 'brand.400')(props),
        bgGradientActive: mode('brand.700', 'brand.500')(props),
        color: mode('white', 'brand.900')(props),
        boxShadowFocus: 'outline-brand',
      }),
    '@secondary': (props) =>
      customVariant({
        bg: mode('brand.50', 'brand.800')(props),
        bgHover: mode('brand.100', 'brand.700')(props),
        bgActive: mode('brand.200', 'brand.600')(props),
        color: mode('brand.600', 'brand.50')(props),
        colorHover: mode('brand.700', 'brand.100')(props),
        boxShadowFocus: 'outline-brand',
      }),
    '@secondaryOutlined': () =>
      customVariant({
        bg: 'white',
        border: {
          color: 'secondary.500',
          size: '2px',
        },
        color: 'secondary.500',
        bgHover: 'secondary.500',
        colorHover: 'white',
        bgActive: 'secondary.800',
        colorActive: 'white',
        boxShadowFocus: 'outline-brand',
      }),
    '@purple': () =>
      customVariant({
        bg: 'brandBgOpacity',
        color: 'white',
        bgHover: 'brandmatte',
        colorHover: 'white',
        bgActive: 'brandmatte',
        colorActive: 'white',
        boxShadowFocus: 'outline-brand',
      }),

    '@danger': (props) =>
      customVariant({
        bg: mode('red.600', 'red.900')(props),
        bgHover: mode('red.700', 'red.900')(props),
        bgActive: mode('red.900', 'red.900')(props),
        color: mode('white', 'white')(props),
        colorHover: mode('white', 'white')(props),
        boxShadowFocus: 'outline-error',
      }),
    '@warning': (props) =>
      customVariant({
        bg: mode('warning.50', 'warning.900')(props),
        bgHover: mode('warning.100', 'warning.800')(props),
        bgActive: mode('warning.200', 'warning.700')(props),
        color: mode('warning.600', 'warning.50')(props),
        colorHover: mode('warning.700', 'warning.100')(props),
        boxShadowFocus: 'outline-warning',
      }),
    '@createEvent': () => ({
      pos: 'absolute',
      top: [5, 10],
      right: [24, 24, 32, 32],
      zIndex: 1000,
      bg: 'white',
      color: 'black',
      _hover: {
        bg: 'whiteAlpha.800',
      },
    }),
    '@subscribe': (props) =>
      customVariant({
        bg: mode('brandregular', 'brandregular')(props),
        bgHover: mode('brandlight', 'brandlight')(props),
        bgActive: mode('brandregularDark', 'brandregularDark')(props),
        color: mode('gray.100', 'gray.100')(props),
        colorHover: mode('gray.200', 'gray.200')(props),
        boxShadowFocus: 'none',
      }),
    '@alreadySubscribe': (props) =>
      customVariant({
        bg: mode('gray.500', 'gray.500')(props),
        bgHover: mode('gray.400', 'gray.400')(props),
        bgActive: mode('gray.700', 'gray.700')(props),
        color: mode('white', 'white')(props),
        colorHover: mode('white', 'white')(props),
        boxShadowFocus: 'none',
        opacity: '70%',
      }),

    // Default variants
    transparent: (props) => ({
      bg: transparentize(`${props.colorScheme}.50`, 0.05)(props.theme),
      _hover: {
        bg: transparentize(`${props.colorScheme}.50`, 0.15)(props.theme),
      },
      border: '1px',
      borderColor: 'white',
    }),
    solid: () => ({
      bg: 'white',
      color: 'black',
      _hover: {
        bg: 'whiteAlpha.800',
      },
    }),
    ghost: (props) => ({
      bg: transparentize(`${props.colorScheme}.50`, 0.05)(props.theme),
      _hover: {
        bg: transparentize(`${props.colorScheme}.50`, 0.15)(props.theme),
      },
      _active: {
        bg: transparentize(`${props.colorScheme}.50`, 0.15)(props.theme),
      },
    }),
  },
};
