export default {
  variants: {
    outline: () => ({
      bg: 'whiteAlpha.400',
      borderColor: 'whiteAlpha.700',
      color: 'white',
    }),
  },
  defaultProps: {
    variant: 'outline',
  },
};
