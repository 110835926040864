import create from 'zustand';

type DialogState = {
  isOpen: boolean;
  userWantUnsubscribe: boolean;
  setUserDecision: (value: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
};

export const useAlertDialog = create<DialogState>((set) => ({
  isOpen: false,
  userWantUnsubscribe: false,
  onOpen: () =>
    set((state) => ({
      ...state,
      isOpen: true,
    })),
  onClose: () =>
    set((state) => ({
      ...state,
      isOpen: false,
    })),
  setUserDecision: (value) =>
    set((state) => ({
      ...state,
      userWantUnsubscribe: value,
    })),
}));
