import { GlobalStyleProps } from '@chakra-ui/theme-tools';

import * as externals from './externals';

const externalsStyles = (props: GlobalStyleProps) =>
  Object.values(externals).reduce(
    (acc, cur) => ({
      ...acc,
      ...(typeof cur === 'function' ? cur(props) : cur),
    }),
    {},
  );

export const styles = {
  global: (props: GlobalStyleProps) => ({
    html: {
      minH: '100vh',
    },
    body: {
      h: '100%',
      WebkitTapHighlightColor: 'transparent',
      color: 'white',
    },
    button: {
      cursor: 'pointer',
    },
    '#chakra-toast-portal > *': {
      pt: 'safe-top',
      pl: 'safe-left',
      pr: 'safe-right',
      pb: 'safe-bottom',
    },
    ...externalsStyles(props),
  }),
};
