import {
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { RegisterCredentials } from '../../api/types';
import { EmailSendedModal } from '../EmailSendedModal';

import schema from './schema';

import { Link } from '@/components/common/Link';
import { FieldWrapper, Form, TextInput, TextInputMask } from '@/components/Form';
import { useAuth } from '@/lib/authentication';

type RegisterFormProps = {
  onError: (message: string) => void;
};

export const RegisterForm = ({ onError }: RegisterFormProps) => {
  const intl = useIntl();
  const toast = useToast();
  const [email, setEmail] = useState();
  const { register, isRegistering } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <Form<RegisterCredentials>
      initialValues={{
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        language: 'pt-br',
        role: 'common',
      }}
      onSubmit={async (values) => {
        try {
          const form: any = new FormData();
          const phoneFormatted = values.phone.replace('(', '').replace(')', '').replace(/\s/g, '');

          const nextValues = {
            ...values,
            phone: phoneFormatted,
          };

          Object.keys(nextValues).forEach((key) => form.set(key, nextValues[key]));

          const response: any = await register(form);
          setEmail(response.user.email);

          if (response.user.emailVerified) onOpen();
          onOpen();
          toast({
            title: response.message,
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        } catch (err: any) {
          onError(err?.response?.data?.message || 'Erro no cadastro');
        }
      }}
      validationSchema={schema}
    >
      {() => (
        <Stack pb="8" spacing={0}>
          <EmailSendedModal email={email} isOpen={isOpen} onClose={onClose} />
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="name"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_name',
              })}
              as={(props) => <TextInput {...props} />}
            />
          </HStack>
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="email"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_email',
              })}
              as={(props) => <TextInput {...props} />}
            />
          </HStack>
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="phone"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_phone',
              })}
              as={(props) => <TextInputMask mask="(**) *********" {...props} />}
            />
          </HStack>
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="password"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_password',
              })}
              as={(props) => <TextInput type="password" {...props} />}
            />
          </HStack>
          <HStack
            w="100%"
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
            flexDir={['column', 'row']}
            spacing={0}
            mb={[4, 0]}
          >
            <FieldWrapper
              name="confirmPassword"
              required
              label={intl.formatMessage({
                id: 'auth_registerform_confirmpassword',
              })}
              as={(props) => <TextInput type="password" {...props} />}
            />
          </HStack>
          <VStack alignItems="center" justifyContent="space-between" mx="auto">
            <Box w={['80%', '80%', '50%', '50%']}>
              <Button
                isLoading={isRegistering}
                type="submit"
                className="w-full"
                variant="@primaryGradient"
                w="100%"
              >
                {intl.formatMessage({
                  id: 'auth_registerform_button',
                })}
              </Button>
            </Box>
            <Stack alignItems="center" direction={['column', 'column', 'row', 'row']}>
              <Text fontSize="sm">
                {intl.formatMessage({
                  id: 'auth_have_account',
                })}
              </Text>
              <Text
                as={Link}
                _hover={{ textDecoration: 'none', opacity: 0.8 }}
                to="/login"
                fontWeight="bold"
              >
                {intl.formatMessage({
                  id: 'auth_have_account_login',
                })}
              </Text>
            </Stack>
          </VStack>
        </Stack>
      )}
    </Form>
  );
};
