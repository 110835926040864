import create from 'zustand';

type SearchState = {
  searchTerm: string;
  addNewSearchTerm: (searchTerm: string) => void;
};

export const useSearchEvent = create<SearchState>((set) => ({
  searchTerm: '',
  addNewSearchTerm: (searchTerm) =>
    set({
      searchTerm: searchTerm,
    }),
}));
