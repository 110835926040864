import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  SlideFade,
  FormControlProps,
  Text,
  Box,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { FcInfo } from 'react-icons/fc';
import { FiAlertCircle } from 'react-icons/fi';

import { Icon } from '@/components/common/Icon';

export interface FormGroupProps
  extends Omit<FormControlProps, 'onChange' | 'defaultValue' | 'label'> {
  children?: React.ReactNode;
  error?: string;
  helper?: React.ReactNode;
  id?: string;
  required?: boolean;
  label?: React.ReactNode;
  showError?: boolean;
  formGroupPosition?: any;
  tooltipMessage?: string;
}

export const FormGroup = ({
  children,
  error,
  helper,
  id,
  required,
  label,
  tooltipMessage,
  formGroupPosition,
  ...props
}: FormGroupProps) => (
  <FormControl
    isInvalid={!!error}
    isRequired={required}
    mb={5}
    pos={formGroupPosition || 'relative'}
    {...props}
  >
    {!!label && (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormLabel htmlFor={id}>{label}</FormLabel>
        {tooltipMessage && (
          <Tooltip hasArrow placement="auto" label={tooltipMessage}>
            <Box pb="2">
              <FcInfo />
            </Box>
          </Tooltip>
        )}
      </Stack>
    )}
    {children}
    {!!helper && <FormHelperText>{helper}</FormHelperText>}

    <FormErrorMessage>
      <SlideFade in offsetY={-6}>
        <Box d="flex">
          <Icon icon={FiAlertCircle} color="yellow.400" />
          <Text ml={2} color="yellow.400">
            {error}
          </Text>
        </Box>
      </SlideFade>
    </FormErrorMessage>
  </FormControl>
);
