import { theme } from '@chakra-ui/react';

import tailwindColors from './tailwindColors';

export const colors = {
  ...theme.colors,
  ...tailwindColors,
  brand: {
    50: '#5e43ed',
    100: '#3616e7',
    200: '#3114d0',
    300: '#2b12b9',
    400: '#260fa2',
    500: '#1B0B73',
    600: '#100744',
    700: '#0b042d',
    800: '#050216',
    900: '#000000',
  },
  secondary: {
    50: '#e3a6f0',
    100: '#d57ae8',
    200: '#ce64e5',
    300: '#c84fe1',
    400: '#c139dd',
    500: '#A621C2',
    600: '#811a96',
    700: '#6e1681',
    800: '#5b126b',
    900: '#360b3f',
  },
  bglight: '#687df5',
  brandlight: '#09bffe',
  brandregular: '#019dd3',
  brandregularDark: '#0177a0',
  brandmatte: '#5640B1',
  brandSecondaryLight: '#3854F280',
  brandBgOpacity: '#62589ddc',
  brandBgOpacityDark: '#433d6cdb',
  primaryNavbarButton: '#6F20D9',
  gray: tailwindColors.blueGray,
  success: theme.colors.green,
  error: theme.colors.red,
  warning: theme.colors.yellow,
};
