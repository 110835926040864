import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { SENTRY_ENVIRONMENT, SENTRY_TOKEN } from './config';
import reportWebVitals from './reportWebVitals';

dayjs.extend(customParseFormat);

Sentry.init({
  dsn: SENTRY_TOKEN,
  integrations: [new BrowserTracing()],
  environment: SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
