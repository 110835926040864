import { initReactQueryAuth } from './context';
import history from './history';

import { Spinner } from '@/components/common/Spinner';
import {
  loginWithEmailAndPassword,
  loginWithGoogle,
  loginWithFacebook,
  registerWithEmailAndPassword,
  UserResponse,
  LoginCredentials,
  RegisterCredentials,
  refetchUserWithId,
  RegisterUserResponse,
} from '@/modules/auth';
import { storage, cookies } from '@/utils';

function handleUserResponse(data: UserResponse) {
  const { accessToken, refreshToken, user } = data;

  storage.setUser(user);
  cookies.setAccess(accessToken);
  cookies.setRefresh(refreshToken);
  return user;
}

async function loadUser(): Promise<any> {
  const user: User = storage.getUser();
  const access = cookies.getAccess();

  if (!user || !access) {
    storage.clearUser();
    cookies.clearAccess();
    cookies.clearRefresh();
    return null;
  }

  const nextUser = await refetchUserWithId(user.id);

  if (nextUser) return nextUser;
}

async function loginFn({
  data,
  isGmail = false,
  isFacebook = false,
  isClapme = false,
  res,
}: {
  data: LoginCredentials;
  isGmail?: boolean;
  isFacebook?: boolean;
  isClapme?: boolean;
  res?: any;
}): Promise<any> {
  const nextGoogleData = {
    accessToken: res?.accessToken,
    tokenId: res?.tokenId,
    name: res?.profileObj?.name,
  };

  const nextFacebookData = {
    accessToken: res?.accessToken,
    name: res?.name,
  };

  if (isClapme) {
    const response = await loginWithEmailAndPassword(data);
    const user = handleUserResponse(response);
    return user;
  }

  if (isGmail) {
    const response = await loginWithGoogle(nextGoogleData);
    const user = handleUserResponse(response);
    return user;
  }

  if (isFacebook) {
    const response = await loginWithFacebook(nextFacebookData);
    const user = handleUserResponse(response);
    return user;
  }

  return null;
}

async function registerFn(data: RegisterCredentials): Promise<any> {
  const { message, user } = await registerWithEmailAndPassword(data);
  return { user, message };
}

async function logoutFn() {
  storage.clearUser();
  cookies.clearAccess();
  history.push('/login');
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return <Spinner />;
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  User | null,
  unknown,
  {
    data: LoginCredentials;
    isGmail?: boolean;
    isFacebook?: boolean;
    isClapme?: boolean;
    res?: any;
  },
  RegisterUserResponse
>(authConfig);
