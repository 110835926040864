import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';

import { useForgotPassword } from '../../../hooks';

import schema from './schema';

import { FieldWrapper, Form, TextInput } from '@/components/Form';

type InitiateFormProps = {
  email: string;
};

export const InitiateForm = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const forgotPasswordMutation = useForgotPassword({});
  const [isSmallScreen] = useMediaQuery('(max-width: 450px)');

  return (
    <Stack
      w={['100%', '100%', '80%', '80%', '85%']}
      color="white"
      flex={1}
      bg="#62589ddc"
      boxShadow="md"
      px={6}
      py="6"
      borderRadius={10}
      alignItems="center"
      justifyContent="center"
    >
      <VStack pb={forgotPasswordMutation.isSuccess ? '2' : '8'}>
        <Heading w="100%" mt="2" mb="2" textAlign="center" fontWeight="semibold">
          {forgotPasswordMutation.isSuccess ? 'Email   enviado ' : 'Recuperar senha'}
        </Heading>
        <Text color="gray.300" textAlign="center">
          {forgotPasswordMutation.isSuccess
            ? 'Email de recuperação enviado com sucesso'
            : 'Informe seu e-mail de recuperação'}
        </Text>
      </VStack>

      <Box w="full" px={4}>
        <Form<InitiateFormProps>
          initialValues={{
            email: '',
          }}
          onSubmit={async ({ email }) => {
            try {
              await forgotPasswordMutation.mutateAsync({ email });
            } catch (err: any) {
              toast({
                description: err?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
            }
          }}
          validationSchema={schema}
        >
          {({ values }) =>
            forgotPasswordMutation.isSuccess ? (
              <Stack w="100%" alignItems="center" justifyContent="center">
                <Text textAlign="center" mb="4">
                  Nós enviamos um email para <strong>{values.email}</strong>, cheque sua caixa de
                  entrada ou spam, caso não tenha recebido tente novamente.
                </Text>
                <Button variant="@primary" onClick={() => navigate('/login')}>
                  Voltar
                </Button>
              </Stack>
            ) : (
              <Stack w="100%" px="6">
                <FieldWrapper
                  name="email"
                  label=""
                  required
                  as={(props) => (
                    <TextInput _placeholder={{ color: 'white' }} placeholder="Email" {...props} />
                  )}
                />
                <Stack px="4" alignItems="center" justifyContent="center">
                  <Button
                    type="submit"
                    color="white"
                    variant="@primary"
                    _hover={{ color: 'gray.300' }}
                    isLoading={forgotPasswordMutation.isLoading}
                  >
                    {isSmallScreen ? 'Enviar email' : 'Enviar email de recuperação'}
                  </Button>
                </Stack>
              </Stack>
            )
          }
        </Form>
      </Box>
    </Stack>
  );
};
