import { Box, Center, Image, Text, useRadio, VStack } from '@chakra-ui/react';
import { AiFillYoutube } from 'react-icons/ai';
import { FaVimeoV } from 'react-icons/fa';
import { SiMicrosoftteams, SiZoom } from 'react-icons/si';

import LogoCollapsedSvg from '@/assets/clapme-logo-white.svg';

export const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <VStack>
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          border="2px solid white"
          w="130px"
          h="120px"
          d="flex"
          alignItems="center"
          justifyContent="center"
          rounded="md"
          _checked={{
            color: 'blue.400',
            borderColor: 'blue.400',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          borderColor={`${props.error && 'red.500'}`}
        >
          {props.value === 'clapMe' && (
            <Center boxSize="50px">
              <Image
                filter={`${
                  props.isChecked &&
                  'invert(71%) sepia(36%) saturate(5652%) hue-rotate(189deg) brightness(101%) contrast(96%)'
                }`}
                src={LogoCollapsedSvg}
              />
            </Center>
          )}
          {props.value === 'youtube' && (
            <Center boxSize="60px">
              <AiFillYoutube size={100} />
            </Center>
          )}
          {props.value === 'vimeo' && (
            <Center boxSize="60px">
              <FaVimeoV size={100} />
            </Center>
          )}
          {props.value === 'teams' && (
            <Center boxSize="60px">
              <SiMicrosoftteams size={100} />
            </Center>
          )}
          {props.value === 'zoom' && (
            <Center boxSize="60px">
              <SiZoom size={100} />
            </Center>
          )}
        </Box>
        <Text
          color={`${props.isChecked && 'blue.400'}`}
          mt={3}
          fontWeight="bold"
          textTransform="capitalize"
        >
          {props.value === 'youtube' ? 'YouTube' : props.value}
        </Text>
      </Box>
    </VStack>
  );
};
