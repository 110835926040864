import { Box, Button, Center, Heading, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Page } from '@/components/Page';

export const GenericError = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <Center w="100%" h="100%" alignItems="center" justifyContent="center" mt={50}>
        <VStack spacing={0}>
          <Heading
            textAlign="center"
            fontSize={['5xl', '6xl', '7xl', '8xl', '9xl']}
            letterSpacing={['5px', '5px', '15px', '15px']}
          >
            Erro
          </Heading>
          <VStack spacing={-6}>
            <Heading
              fontSize={['md', 'md', 'lg', 'xl', '2xl']}
              textAlign="center"
              letterSpacing="2px"
            >
              Desculpe ocorreu um erro, tente novamente mais tarde.
            </Heading>
          </VStack>
          <Box pt={10}>
            <Button size="lg" variant="@subscribe" rounded="sm" onClick={() => navigate('/')}>
              VOLTAR AO INÍCIO
            </Button>
          </Box>
        </VStack>
      </Center>
    </Page>
  );
};
