import { useState, createContext, useContext } from 'react';
import { IntlProvider as OriginalIntlProvider } from 'react-intl';

import { messages } from '..';
import { LOCALES } from '../locales';
import { Locales } from '../types';

import { useLocaleStore } from '@/store';

type LocaleProviderProps = {
  currentLocale: Locales;
  setCurrentLocale: (locale: Locales) => void;
};

const LocaleContext = createContext<LocaleProviderProps>({
  currentLocale: 'en-US',
  setCurrentLocale: () => ({}),
});

export const IntlProvider = ({ children }) => {
  const { locale } = useLocaleStore();
  const [currentLocale, setCurrentLocale] = useState<Locales>(locale ? locale : 'pt-BR');

  return (
    <LocaleContext.Provider
      value={{
        currentLocale,
        setCurrentLocale,
      }}
    >
      <OriginalIntlProvider
        messages={messages[currentLocale]}
        locale={currentLocale}
        defaultLocale={LOCALES.ENGLISH}
      >
        {children}
      </OriginalIntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
