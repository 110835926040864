import { FieldProps } from 'formik';
import TimezoneSelect, { allTimezones, Props } from 'react-timezone-select';

import { styles } from '../../styles';

interface TimezoneSelectFieldProps
  extends Omit<Props, 'form' | 'value'>,
    Omit<FieldProps, 'value'> {
  value?: string;
}

export const TimezoneSelectField = ({ field, form, ...restProps }: TimezoneSelectFieldProps) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const $setFieldValue = (value: any) => setFieldValue(name, value);

  return (
    <TimezoneSelect
      value={value || ''}
      styles={styles}
      placeholder="Selecione..."
      onChange={(e) => $setFieldValue(e.value)}
      noOptionsMessage={({ inputValue }) => (!inputValue ? 'Sem opções' : 'Sem resultados')}
      timezones={{
        ...allTimezones,
      }}
      {...restProps}
    />
  );
};
