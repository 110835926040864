import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

import { styles } from './chakra-styles';
import * as components from './components';
import { config } from './config';
import foundations from './foundations';

const breakpoints = createBreakpoints({
  sm: '375px',
  md: '768px',
  lg: '1366px',
  xl: '1650px',
  '2xl': '1910px',
});

export default extendTheme({
  config,
  styles,
  breakpoints,
  ...foundations,
  components: { ...(components as any) },
});
