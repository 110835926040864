import { useToast } from '@chakra-ui/react';

import { RegisterForm } from '../components/RegisterForm';
import { RegisterLayout } from '../components/RegisterLayout';

export const Register = () => {
  const toast = useToast();

  return (
    <RegisterLayout>
      <RegisterForm
        onError={(message) => {
          return toast({
            title: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }}
      />
    </RegisterLayout>
  );
};
