import { Box, Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Page } from '@/components/Page';

type Error400Props = {
  errorMessage?: string;
  errorCode?: number;
};

export const Error400 = ({ errorCode, errorMessage }: Error400Props) => {
  const navigate = useNavigate();

  return (
    <Page>
      <Center w="100%" h="100%" alignItems="center" justifyContent="center" mt={50}>
        <VStack spacing={2}>
          <Heading
            textAlign="center"
            fontSize={['5xl', '6xl', '7xl', '8xl', '9xl']}
            letterSpacing={['10px', '10px', '40px', '40px']}
          >
            {errorCode}
          </Heading>
          <VStack spacing={-6}>
            <Heading
              fontSize={['xl', '2xl', '3xl', '4xl', '5xl']}
              textAlign="center"
              letterSpacing="4px"
            >
              {errorMessage}
            </Heading>
            <Text textAlign="center" fontWeight="light" fontSize={['md', 'lg', 'xl', 'xl']}>
              Desculpe, ocorreu um erro ao realizar a requisição
            </Text>
          </VStack>
          <Box pt={10}>
            <Button size="lg" variant="@subscribe" rounded="sm" onClick={() => navigate('/')}>
              VOLTAR AO INÍCIO
            </Button>
          </Box>
        </VStack>
      </Center>
    </Page>
  );
};
