export const APP_NAME = 'ClapMe';

export const BRAND_COLOR = '#1B0B73';

export const API_URL = process.env.REACT_APP_API_URL as string;
export const MOCK_API_URL = process.env.REACT_APP_MOCK_API_URL as string;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_ID as string;
export const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_ID as string;

export const SENTRY_TOKEN = process.env.REACT_APP_SENTRY_TOKEN as string;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT as string;

export const ENVIROMENT = process.env.NODE_ENV;

export const DEFAULT_PUBLIC_PAGE = '/entrar';

export const DEFAULT_PRIVATE_PAGE = '/';

export const ACCESS_TOKEN_ID = '_clapme_access';
export const REFRESH_TOKEN_ID = '_clapme_refresh';
export const USER_INFO_ID = '_clapme_user-info';

export const MSW_DB = '_clapme_msw-db';

export const JWT_ACCESS_SECRET = '123456' as string;
export const JWT_ACCESS_EXPIRES_IN = 10;
export const JWT_REFRESH_SECRET = '123456789' as string;
export const JWT_REFRESH_EXPIRES_IN = 50;

export const MIN_TAX_VALUE = Number(process.env.REACT_APP_MIN_TAX_VALUE);
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL as string;
