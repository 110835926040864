import { Button, Container, Heading, Stack, Text, useToast, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';

import { ConfirmInviteRequest } from '../api';

import { Logo } from '@/components/common/Logo';
import { Spinner } from '@/components/common/Spinner';
import { Head } from '@/components/Head';
import * as LC from '@/components/LC';
import { useAuth } from '@/lib/authentication';

function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export const ConfirmInvite = () => {
  const toast = useToast();
  const query = useQuery();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const type = query.get('type');
  const name = query.get('name');
  const token = query.get('token');
  const eventId = query.get('eventId');
  const stageId = query.get('stageId');
  const requestId = stageId || eventId;

  const nameFormatted = name?.replaceAll('-', ' ');

  const { mutateAsync, isLoading } = useMutation({
    useErrorBoundary: false,
    mutationFn: ConfirmInviteRequest,
    onError: (error: any) => {
      toast({
        title: error.response.data.message || 'Ocorreu um erro ao tentar confirmar o seu convite.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
    onSuccess: () => {
      toast({
        title: 'Convite confirmado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/', { replace: true });
    },
  });

  useEffect(() => {
    if (!user) {
      toast({
        title: 'Você precisa fazer login para aceitar o convite!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      navigate('/login', { state: { from: pathname } });
    }
    if ((!eventId && !stageId) || !type || !name || !token) navigate('/', { replace: true });
  }, [eventId, name, navigate, pathname, stageId, toast, token, type, user]);

  return (
    <>
      <Head title="Confirm Invite" />
      {!user ? (
        <Spinner />
      ) : (
        <Container minH="100%" maxW="container.sm">
          <LC.Vertical minH="100%" alignItems="center">
            <LC.Vertical flex={1} pt="10" pb="16">
              <Logo
                cursor="pointer"
                onClick={() => navigate('/login', { replace: true })}
                maxW={['300px', '350px', 'sm']}
              />
            </LC.Vertical>
            <Stack minH="100%">
              <Stack
                color="white"
                flex={1}
                bg="#62589ddc"
                boxShadow="md"
                p="6"
                minH="250px"
                borderRadius={10}
                alignItems="center"
                justifyContent="center"
              >
                <VStack pb="2">
                  <Heading
                    w="100%"
                    mt="2"
                    mb="2"
                    fontSize="2xl"
                    textAlign="center"
                    fontWeight="semibold"
                  >
                    Voce foi convidado para ser moderador do {type}{' '}
                    <Text textTransform="capitalize">{nameFormatted}</Text>{' '}
                  </Heading>
                  <Stack justifyContent="center" alignItems="center" spacing={6}>
                    <Text color="gray.100" textAlign="justify" mb="4">
                      Agora você pode fazer login com sua nova conta.
                    </Text>
                    <Stack direction="row">
                      {isLoading ? (
                        <Stack alignItems="center" justifyContent="center" w="100%" h="100%">
                          <Spinner size="xl" />
                        </Stack>
                      ) : (
                        <>
                          <Button
                            variant="@danger"
                            onClick={() => navigate('/', { replace: true })}
                          >
                            Recusar
                          </Button>
                          <Button
                            onClick={() => mutateAsync({ requestId, type, token })}
                            variant="@primary"
                          >
                            Aceitar
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </VStack>
              </Stack>
            </Stack>
          </LC.Vertical>
        </Container>
      )}
    </>
  );
};
