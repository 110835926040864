import { createContext, useState, useContext } from 'react';

import { ThemeVariants } from './types';

type ThemeColorContextProps = {
  theme: ThemeVariants;
  changeTheme: (variant: ThemeVariants) => void;
};

const ThemeColorContext = createContext<ThemeColorContextProps>({
  theme: 'primaryDark',
  changeTheme: () => null,
});

export const ThemeColorProvider = ({ children }) => {
  const [theme, setTheme] = useState<ThemeVariants>('primaryDark');

  const changeTheme = (variant: ThemeVariants) => setTheme(variant);

  const value = {
    theme,
    changeTheme,
  };

  return <ThemeColorContext.Provider value={value}>{children}</ThemeColorContext.Provider>;
};

export const useThemeColor = () => useContext(ThemeColorContext);
