import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { forgotPassword } from '../api';
import { ForgotPasswordCredentials } from '../api/types';

import { MutationConfig, queryClient } from '@/lib/react-query';

type UseForgotPasswordOptions = {
  config?: MutationConfig<typeof forgotPassword>;
};

export const useForgotPassword = ({ config }: UseForgotPasswordOptions) => {
  const toast = useToast();

  return useMutation({
    onMutate: async (newData: ForgotPasswordCredentials) => {
      await queryClient.cancelQueries('forgot-password');

      const previousData = queryClient.getQueryData<any>('forgot-password');

      queryClient.setQueryData('forgot-password', [
        {
          ...newData,
        },
      ]);

      return { previousData };
    },

    onSuccess: () => {
      queryClient.invalidateQueries('forgot-password');
      toast({
        description: 'O código foi enviado para o seu email!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    ...config,
    useErrorBoundary: false,
    mutationFn: forgotPassword,
  });
};
