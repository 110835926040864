import { Center, HStack, Input, Stack, Text, VStack } from '@chakra-ui/react';
import { FieldProps } from 'formik';
import { useRef, useState } from 'react';
import { BsUpload } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';

export interface UploadVideoProps extends FieldProps {
  onChange: (file) => void;
  suggestedSize: string;
  initialSrc?: string;
  maxFileSize?: number;
}

export const UploadVideo = ({
  form,
  field,
  initialSrc,
  suggestedSize,
  onChange: onChangeParent,
  maxFileSize = 5000000,
}: UploadVideoProps) => {
  const { name } = field;
  const { setFieldValue } = form;
  const [source, setSource] = useState<unknown>();
  const inputRef = useRef<HTMLInputElement>(null);

  const $setFieldValue = (value) => setFieldValue(name, value);

  const handleChoose = () => inputRef?.current?.click();

  const onChange = (event) => {
    const file = event.target.files[0];
    setSource(file);
    onChangeParent(file);
    $setFieldValue(file);
  };

  return (
    <Stack
      p={6}
      bg="brandBgOpacity"
      width="100%"
      height="120px"
      direction="row"
      outlineOffset="-8px"
      outline={`2.5px dashed ${source || initialSrc ? '#20b95b' : '#d9d9d9'}`}
      onClick={handleChoose}
      cursor="pointer"
    >
      <Input
        d="none"
        ref={inputRef}
        type="file"
        onChange={onChange}
        accept=".mov,.mp4"
        maxFileSize={maxFileSize}
      />

      <Stack w="100%" direction="row" alignItems="center" justifyContent="center" spacing={6}>
        {source ? (
          <Center>
            <FaCheck size={50} color="#20b95b" />
          </Center>
        ) : initialSrc ? (
          <Center>
            <FaCheck size={50} color="#20b95b" />
          </Center>
        ) : (
          <HStack w="100%" alignItems="flex-start" justifyContent="flex-start" spacing="6">
            <BsUpload size={40} color="white" />
            <VStack alignItems="flex-start" spacing={0}>
              <Text fontSize={['xs', 'xs', 'sm']}>Selecionar imagem</Text>
              <Text fontSize={['xx-small', 'xs', 'xs', 'xs']}>
                Tamanho sugerido {suggestedSize}
              </Text>
              <Text fontSize={['xx-small', 'xs', 'xs', 'xs']}>
                Tamanho limite do arquivo {maxFileSize / 1000000} mb
              </Text>
            </VStack>
          </HStack>
        )}
      </Stack>
    </Stack>
  );
};
