import {
  Box,
  Center,
  Divider,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { RiMailCloseFill } from 'react-icons/ri';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { resendEmailConfirmation } from '../../api';

export const ConfirmEmailErrorModal = ({ isOpen, onClose, email }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync, isLoading } = useMutation({
    useErrorBoundary: false,
    mutationFn: resendEmailConfirmation,
    onSuccess: () => {
      toast({
        title: 'Email reenviado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro, tente novamente mais tarde.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate('/login');
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="whiteAlpha.50" backdropFilter="blur(5px) hue-rotate(15deg)" />
      <ModalContent
        rounded="md"
        color="black"
        bg="white"
        h={['', '500px', '400px', '400px', '400px']}
        width={{ base: 'calc(100% - 50px)' }}
      >
        <ModalCloseButton color="white" />
        <Center w="100%" h="100%">
          <VStack w="100%" h="100%">
            <Stack
              flex="1"
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="100%"
              bg="red.500"
              rounded="md"
            >
              <Box p="4" border="6px solid white" borderRadius="50%">
                <RiMailCloseFill size={70} color="#fff" />
              </Box>
            </Stack>
            <Stack
              flex="1.1"
              alignItems="center"
              px={['4', '6', '3', '3', '4']}
              justifyContent="center"
              pb="5"
              w="100%"
              h="100%"
            >
              <Heading mb="0" pb="4" textAlign="center" fontSize="2xl" fontWeight="normal">
                Você precisa confirmar seu email
              </Heading>
              <Text fontSize={['sm', 'md', 'md', 'md', 'md']} textAlign="center">
                Para fazer login é necessário que seu email seja válido, para isso enviamos um email
                de confirmação.
              </Text>

              <Box w="100%" px="6" pt="2" pb="3">
                <Divider />
              </Box>
              <Stack
                direction={['column', 'column', 'row', 'row', 'row']}
                alignItems="center"
                p={0}
              >
                <Text fontSize={['sm', 'md', 'md', 'md', 'md']} textAlign="center">
                  Se voce não recebeu nenhum email
                </Text>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Text
                    color="blue.500"
                    fontWeight="bold"
                    cursor="pointer"
                    _hover={{ opacity: 0.8 }}
                    onClick={async () => await mutateAsync({ email: email })}
                  >
                    Reenviar email
                  </Text>
                )}
              </Stack>
            </Stack>
          </VStack>
        </Center>
      </ModalContent>
    </Modal>
  );
};
