import { VStack, Text, Stack, Center, HStack } from '@chakra-ui/react';
import { useState } from 'react';
import { BsUpload } from 'react-icons/bs';
import { FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import ImageUploading, { ImageListType } from 'react-images-uploading';

type UploadImageProps = {
  onChange: (image: ImageListType) => void;
  initialSrc?: string;
  suggestedSize?: string;
  maxSize?: number;
};

// const suggestedSizeConvertInWidthAnHeight = (suggestedSize: string | undefined) => {
//   //troque tudo que não for número, mas o 'x' fica como espaço vazio
//   const onlyNumbers = suggestedSize?.replace(/\D*x/g, '');
//   const [width, height] = onlyNumbers?.split(' ').map(Number) || [];

//   return {
//     width,
//     height,
//   };
// };

export const UploadImage = ({
  onChange: onChangeParent,
  initialSrc,
  suggestedSize,
  maxSize = 1000000,
}: UploadImageProps) => {
  const [images, setImages] = useState<ImageListType>([]);
  // const { height, width } = suggestedSizeConvertInWidthAnHeight(suggestedSize);
  const onChange = (imageList: ImageListType) => {
    onChangeParent(imageList[0] as ImageListType);
    setImages(imageList);
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={1}
      dataURLKey="data_url"
      maxFileSize={maxSize}
    >
      {({ imageList, onImageUpdate, dragProps, errors }) => {
        const isMaxSizeFileError = !!errors?.maxFileSize;

        return (
          <Stack
            p={6}
            bg="brandBgOpacity"
            width="100%"
            height="120px"
            direction="row"
            outlineOffset="-8px"
            outline={`2.5px dashed ${
              isMaxSizeFileError
                ? '#b92a20'
                : initialSrc || imageList.length > 0
                ? '#20b95b'
                : '#d9d9d9'
            }`}
            onClick={() => onImageUpdate(0)}
            cursor="pointer"
            {...dragProps}
          >
            <Stack w="100%" direction="row" alignItems="center" justifyContent="center" spacing={6}>
              {isMaxSizeFileError ? (
                <HStack w="100%" alignItems="center" justifyContent="flex-start" spacing={4}>
                  <FaExclamationTriangle size={50} fill="#b92a20" />
                  <VStack alignItems="flex-start" spacing={0}>
                    <Text fontSize={['xs', 'xs', 'sm']}>Selecionar nova imagem</Text>
                    <Text fontSize={['xx-small', 'xs', 'xs', 'xs']}>
                      Tamanho máximo de {maxSize / 1000000} mb ultrapassado
                    </Text>
                  </VStack>
                </HStack>
              ) : initialSrc || imageList.length > 0 ? (
                <Center>
                  <FaCheck size={50} color="#20b95b" />
                </Center>
              ) : (
                <HStack w="100%" alignItems="flex-start" justifyContent="flex-start" spacing="6">
                  <BsUpload size={40} color="white" />
                  <VStack alignItems="flex-start" spacing={0} textAlign="left">
                    <Text fontSize={['xs', 'xs', 'sm']}>Selecionar imagem</Text>
                    <Text fontSize={['xx-small', 'xs', 'xs', 'xs']}>
                      Tamanho sugerido {suggestedSize}
                    </Text>
                  </VStack>
                </HStack>
              )}
            </Stack>
          </Stack>
        );
      }}
    </ImageUploading>
  );
};
