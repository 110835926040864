import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS, ptBR } from 'date-fns/locale';
import { FieldProps } from 'formik';
import DatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import { AiOutlineCalendar } from 'react-icons/ai';

import { useLocale } from '@/i18n';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

registerLocale('ptBR', ptBR);
registerLocale('enUS', enUS);

type ReactDayPickerInputProps = {
  value?: string;
  onClick?: string;
  onChange?: string;
  placeholder?: string;
};

export interface DatePickerProps extends Omit<ReactDatePickerProps, 'onChange'>, FieldProps {
  inputProps?: any;
  dayPickerProps?: any;
  placeholder?: string;
  timezone?: string;
}

const CustomInput = ({ value, onClick, onChange, ...rest }: ReactDayPickerInputProps) => (
  <InputGroup>
    <InputLeftElement>
      <AiOutlineCalendar size="20" />
    </InputLeftElement>
    <Input value={value} onClick={onClick as any} readOnly onChange={onChange as any} {...rest} />
  </InputGroup>
);

export const DateField = ({ field, timezone, form, ...rest }: DatePickerProps) => {
  let newTimezone = '';
  const { name, value } = field;
  const { setFieldValue } = form;
  const { currentLocale } = useLocale();

  const dateFormat = currentLocale === 'pt-BR' ? 'dd/MM/yyyy H:mm aa' : 'MM/dd/yyyy h:mm aa';
  const defaultTimezone = currentLocale === 'pt-BR' ? 'America/Sao_Paulo' : 'America/Los_Angeles';

  if (timezone) newTimezone = timezone;
  else newTimezone = defaultTimezone;

  const formatDateWithTimezone = (date) => {
    const dateWithZone = formatInTimeZone(date, newTimezone, 'yyyy-MM-dd HH:mm:ss');
    return new Date(dateWithZone);
  };

  const $setFieldValue = (value) => {
    const dateFormatted = formatDateWithTimezone(value);
    setFieldValue(name, dateFormatted);
  };

  return (
    <DatePicker
      {...rest}
      showTimeSelect
      timeIntervals={15}
      dateFormat={dateFormat}
      customInput={<CustomInput />}
      onChange={(date: Date) => $setFieldValue(date)}
      locale={currentLocale === 'pt-BR' ? 'ptBR' : 'enUS'}
      selected={value ? formatDateWithTimezone(value) : null}
      timeCaption={currentLocale === 'pt-BR' ? 'Horário' : 'Time'}
      placeholderText={currentLocale === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
    />
  );
};
