export default {
  variants: {
    outline: () => ({
      field: {
        bg: 'whiteAlpha.400',
        borderColor: 'whiteAlpha.700',
        color: 'white',
      },
    }),
  },
  baseStyle: {
    field: {
      '> option, > optgroup': {
        bg: 'gray.700',
        w: '100%',
        color: 'white',
      },
    },
  },
};
