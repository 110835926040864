import { Box, BoxProps, useMediaQuery } from '@chakra-ui/react';
import { ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { MotionBox } from '@/components/common/MotionBox';
import { Head } from '@/components/Head';

type PageComponentProps = {
  children: ReactNode;
  title?: string;
  withCreateEventButton?: boolean;
} & BoxProps;

export const Page = ({ children, title, ...restProps }: PageComponentProps) => {
  const { pathname } = useLocation();
  const pageRef = useRef<HTMLDivElement>(null);
  const [isSmallScreen] = useMediaQuery('(max-width: 860px)');

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
      },
    },
  };

  useEffect(() => {
    if (pathname && pageRef) {
      pageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <MotionBox
      variants={container}
      initial="hidden"
      animate="show"
      ref={pageRef}
      maxW={{ base: '100%', md: '875px', lg: '1400px', xl: '1700px', '2xl': '1650px' }}
      mx="auto"
    >
      <Head title={title} />

      <Box pt={[4, 4, 12, 12]} mb={`${isSmallScreen ? 32 : 6}`} px={[6, 8]} w="100%" {...restProps}>
        <Box h="100%" w="100%">
          {children}
        </Box>
      </Box>
    </MotionBox>
  );
};
