import { Divider, HStack, Text } from '@chakra-ui/layout';
import { useIntl } from 'react-intl';

import { Locales } from '@/i18n/types';
import { useLocaleStore } from '@/store';

export const LocaleSwitcher = ({ setCurrentLocale }) => {
  const intl = useIntl();
  const { locale: currentLocale, setLocale } = useLocaleStore();

  const handleLocaleChange = (locale: Locales) => {
    if (currentLocale === locale) return;
    setLocale(locale);
    setCurrentLocale(locale);
  };

  return (
    <HStack fontFamily="heading" fontSize="xs" mb={8} spacing={6} px={4}>
      <Text>
        {intl.formatMessage({
          id: 'localeswticher_label',
        })}
      </Text>

      <HStack
        divider={
          <Divider
            orientation="vertical"
            w="100%"
            h="6"
            m="0"
            d="flex"
            alignItems="center"
            justifyContent="center"
          />
        }
        w="fit-content"

        // position="fixed"
        // top={[5, 10]}
        // right={[12, 14, 25, 40]}
      >
        <Text
          cursor="pointer"
          position="relative"
          borderBottom="0px"
          transform={currentLocale === 'pt-BR' ? 'scale(1.1)' : 'scale(1)'}
          transition="all 0.2s cubic-bezier(0.33, 1, 0.68, 1)"
          px={3}
          _after={{
            content: '""',
            d: currentLocale === 'pt-BR' ? 'initial' : 'none',
            position: 'absolute',
            bottom: 0,
            left: 3,
            width: '1.3rem',
            height: '0.1rem',
            bg: 'white',
            transition: 'all 0.2s cubic-bezier(0.33, 1, 0.68, 1)',
          }}
          onClick={() => handleLocaleChange('pt-BR')}
        >
          PT
        </Text>
        <Text
          cursor="pointer"
          position="relative"
          borderBottom="0px"
          transform={currentLocale === 'en-US' ? 'scale(1.1)' : 'scale(1)'}
          transition="all 0.2s cubic-bezier(0.33, 1, 0.68, 1)"
          px={3}
          _after={{
            content: '""',
            d: currentLocale === 'en-US' ? 'initial' : 'none',
            position: 'absolute',
            bottom: 0,
            left: 3,
            width: '1.5rem',
            height: '0.1rem',
            bg: 'white',
            transition: 'all 0.2s cubic-bezier(0.33, 1, 0.68, 1)',
          }}
          onClick={() => handleLocaleChange('en-US')}
        >
          EN
        </Text>
      </HStack>
    </HStack>
  );
};
