import { Box, Button, Container, Heading, Stack, Text, useToast, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import bgDetails1 from '@/assets/bg-details-1.png';
import { Logo } from '@/components/common/Logo';
import { Spinner } from '@/components/common/Spinner';
import { Head } from '@/components/Head';
import * as LC from '@/components/LC';
import { applyActionCode, auth } from '@/lib/firebase';

type ConfirmEmailProps = {
  oobCode: string | null;
};

export const ConfirmEmail = ({ oobCode }: ConfirmEmailProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleVerifyEmail = useCallback(
    (oobCode) => {
      applyActionCode(auth, oobCode)
        .then(() => {
          toast({
            description: 'Email confirmado com sucesso!',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((err) => {
          switch (err.message) {
            case 'Firebase: Error (auth/invalid-action-code).':
              setError('Código inválido');
              break;
            case 'Firebase: Error (auth/expired-action-code).':
              setError('Código expirado');
              break;
            case 'Firebase: Error (auth/user-not-found).':
              setError('Usuário não encontrado');
              break;
            case 'Firebase: Error (auth/user-disabled).':
              setError('Usuário desabilitado');
              break;
            default:
              setError('Ocorreu um erro');
              break;
          }
        })
        .finally(() => setIsLoading(false));

      const timerId = setTimeout(() => {
        navigate('/login', { replace: true });
      }, 10000);

      return () => clearTimeout(timerId);
    },
    [navigate, toast],
  );

  useEffect(() => {
    handleVerifyEmail(oobCode);
    return () => setIsLoading(false);
  }, [handleVerifyEmail, oobCode]);

  return (
    <>
      <Head title="Confirm Email" />
      <Box
        bgImage={bgDetails1}
        bgSize="cover"
        bgPosition="top"
        _before={{
          content: '""',
          w: '100%',
          h: '100%',
          pos: 'fixed',
          minH: '100%',
          right: '0',
          top: '0',
          bg: 'brandmatte',
          bgAttachment: 'fixed',
          zIndex: '-1',
        }}
        pt={-2}
      >
        <Container minH="100%" maxW="container.sm">
          <LC.Vertical minH="100%" alignItems="center">
            <LC.Vertical flex={1} pt="10" pb="16">
              <Logo
                cursor="pointer"
                onClick={() => navigate('/login', { replace: true })}
                maxW={['300px', '350px', 'sm']}
              />
            </LC.Vertical>
            <Stack minH="100%">
              <Stack
                color="white"
                flex={1}
                bg="#62589ddc"
                boxShadow="md"
                p="6"
                minH="250px"
                w={['300px', '350px', '450px', '450px', '450px']}
                borderRadius={10}
                alignItems="center"
                justifyContent="center"
              >
                {!isLoading ? (
                  <VStack pb="2">
                    <Heading w="100%" mt="2" mb="2" textAlign="center" fontWeight="semibold">
                      {error ? error : 'Email verificado com sucesso!'}
                    </Heading>
                    <Stack justifyContent="center" alignItems="center" spacing={6}>
                      <Text color="gray.100" textAlign="justify">
                        {error
                          ? 'Ocorreu um erro ao tentar confirmar seu email, por favor tente novamente mais tarde'
                          : ' Agora você pode fazer login com sua nova conta.'}
                      </Text>
                      <Stack w="50%">
                        <Button
                          variant="@primary"
                          onClick={() => navigate('/login', { replace: true })}
                        >
                          Continuar
                        </Button>
                      </Stack>
                    </Stack>
                  </VStack>
                ) : (
                  <Stack alignItems="center" justifyContent="center" w="100%" h="100%">
                    <Spinner size="xl" />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </LC.Vertical>
        </Container>
      </Box>
    </>
  );
};
