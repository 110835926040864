import { Box, Button, Heading, Stack, Text, useToast, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import schema from './schema';

import { Spinner } from '@/components/common/Spinner';
import { FieldWrapper, Form, TextInput } from '@/components/Form';
import { confirmPasswordReset, verifyPasswordResetCode, auth } from '@/lib/firebase';

type FormProps = {
  newPassword: string;
};

type PasswordFormProps = {
  oobCode: string | null;
};

type LoadingProps = {
  verifyCodeRequest?: boolean;
  passwordResetRequest?: boolean;
};

export const PasswordForm = ({ oobCode }: PasswordFormProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState({
    verifyCodeRequest: false,
    passwordResetRequest: false,
  } as LoadingProps);

  useEffect(() => {
    if (oobCode) {
      setIsLoading({ verifyCodeRequest: true });
      verifyPasswordResetCode(auth, oobCode)
        .catch(() => {
          navigate('/login', { replace: true });
          toast({
            description: 'Código inválido.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setIsLoading({ verifyCodeRequest: false });
        });
    } else navigate('/login', { replace: true });

    return () => setIsLoading({ verifyCodeRequest: false });
  }, [navigate, oobCode, toast]);

  return (
    <Stack
      w="100%"
      color="white"
      flex={1}
      bg="#62589ddc"
      boxShadow="md"
      px={6}
      py="6"
      borderRadius={10}
      alignItems="center"
      justifyContent="center"
    >
      {!isLoading.verifyCodeRequest && (
        <VStack pb="8">
          <Heading w="100%" mt="2" mb="2" textAlign="center" fontWeight="semibold">
            Recuperar senha
          </Heading>
          <Text color="gray.300" textAlign="center">
            Informe sua nova senha
          </Text>
        </VStack>
      )}

      <Box w="full" px={4}>
        <Form<FormProps>
          initialValues={{
            newPassword: '',
          }}
          onSubmit={async ({ newPassword }) => {
            setIsLoading({ passwordResetRequest: true });
            if (oobCode) {
              confirmPasswordReset(auth, oobCode, newPassword)
                .then(() => {
                  toast({
                    description: 'Senha alterada com sucesso!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                  navigate('/login', { replace: true });
                })
                .catch((err) => {
                  let message = '';
                  switch (err.message) {
                    case 'Firebase: Error (auth/weak-password).':
                      message = 'Senha muito fraca, por favor tente outra senha';
                      break;
                    case 'Firebase: Error (auth/invalid-action-code).':
                      message = 'Código inválido, por favor tente novamente mais tarde';
                      break;
                    case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
                      message = 'A senha deve ter pelo menos 6 caracteres';
                      break;
                    default:
                      message = 'Ocorreu um erro, por favor tente novamente mais tarde';
                      break;
                  }
                  toast({
                    description: message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                })
                .finally(() => {
                  setIsLoading({ passwordResetRequest: false });
                });
            }
          }}
          validationSchema={schema}
        >
          {() =>
            !isLoading.verifyCodeRequest ? (
              <Stack w="100%" px="6">
                <FieldWrapper
                  required
                  name="newPassword"
                  label="Nova senha"
                  as={(props) => (
                    <TextInput
                      type="password"
                      placeholder="Senha aqui"
                      _placeholder={{ color: 'white' }}
                      {...props}
                    />
                  )}
                />
                <FieldWrapper
                  required
                  name="confirmPassword"
                  label="Confirmar nova senha"
                  as={(props) => (
                    <TextInput
                      type="password"
                      placeholder="Senha aqui"
                      _placeholder={{ color: 'white' }}
                      {...props}
                    />
                  )}
                />
                <Stack px="4" py="4" alignItems="center" justifyContent="center">
                  <Button
                    color="white"
                    type="submit"
                    variant="@primary"
                    isLoading={isLoading.passwordResetRequest}
                    _hover={{ color: 'gray.300' }}
                  >
                    Alterar senha
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <Stack alignItems="center" justifyContent="center">
                <Spinner size="xl" />
              </Stack>
            )
          }
        </Form>
      </Box>
    </Stack>
  );
};
