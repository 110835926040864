import { useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

import { LoginForm } from '../components/LoginForm';
import { LoginLayout } from '../components/LoginLayout';

export const Login = () => {
  const intl = useIntl();
  const toast = useToast();
  let locationToRedirect: string;
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as CustomizedState;

  if (!state) {
    locationToRedirect = '/';
  } else {
    locationToRedirect = state.from;
  }

  return (
    <LoginLayout>
      <LoginForm
        onSuccess={() => {
          toast({
            title: intl.formatMessage({
              id: 'auth_login_successfeedback',
            }),
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          navigate(locationToRedirect, { replace: true });
        }}
        onError={(message) => {
          return toast({
            title: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }}
      />
    </LoginLayout>
  );
};
