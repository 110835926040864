import axiosInstance, { AxiosError } from 'axios';

import history from './history';

import { API_URL, MOCK_API_URL } from '@/config';
import { cookies, storage } from '@/utils';

const config = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const unauthenticatedInstance = axiosInstance.create(config);
const authenticatedInstance = axiosInstance.create(config);

export default {
  unauthorized({ mock = false }: { mock?: boolean }) {
    if (mock) unauthenticatedInstance.defaults.baseURL = MOCK_API_URL;

    const interceptor = unauthenticatedInstance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error: AxiosError) => Promise.reject(error),
    );

    unauthenticatedInstance.interceptors.request.use(
      function (config) {
        unauthenticatedInstance.interceptors.response.eject(interceptor);
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    return unauthenticatedInstance;
  },
  authorized({ mock = false }: { mock?: boolean }) {
    authenticatedInstance.defaults.headers.common.Authorization = `Bearer ${cookies.getAccess()}`;
    if (mock) authenticatedInstance.defaults.baseURL = MOCK_API_URL;

    const interceptor = authenticatedInstance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      async (error: AxiosError) => {
        if (error.response) {
          if (error.response.status === 401) {
            return authenticatedInstance
              .post('/refresh-token', {
                token: cookies.getRefresh(),
              })
              .then(async (response) => {
                cookies.setAccess(response.data?.accessToken);
                cookies.setRefresh(response.data?.refreshToken);
                if (error.response) {
                  error.response.config.headers['Authorization'] =
                    'Bearer ' + response.data.accessToken;
                  return authenticatedInstance(error.response.config);
                }
              })
              .catch((error) => {
                if (error.response.status === 403) {
                  cookies.clearAccess();
                  cookies.clearRefresh();
                  storage.clearUser();
                  history.push('/login');
                }
              });
          }
          return Promise.reject(error);
        }
      },
    );

    authenticatedInstance.interceptors.request.use(
      function (config) {
        authenticatedInstance.interceptors.response.eject(interceptor);
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    return authenticatedInstance;
  },
};
