import { Box, Button, Heading, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { LoginCredentials } from '../../api/types';
import { ConfirmEmailErrorModal } from '../ConfirmEmailErrorModal';

import schema from './schema';

import { Link } from '@/components/common/Link';
import { FieldWrapper, Form, TextInput } from '@/components/Form';
import * as LC from '@/components/LC';
import { useLocale } from '@/i18n';
import { useAuth } from '@/lib/authentication';

type LoginFormProps = {
  onSuccess: () => void;
  onError: (message: string) => void;
};

export const LoginForm = ({ onSuccess, onError }: LoginFormProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { currentLocale } = useLocale();
  const { login, isLoggingIn } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');

  return (
    <Form<LoginCredentials>
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async (values) => {
        try {
          await login({ data: { ...values }, isClapme: true });
          onSuccess();
        } catch (err: any) {
          if (err.response.data.message === 'Você precisar confirmar seu email') {
            setEmail(values.email);
            onOpen();
          } else onError(err?.response?.data?.message || 'Erro no login');
        }
      }}
      validationSchema={schema}
    >
      {() => (
        <LC.Vertical w="100%">
          <ConfirmEmailErrorModal isOpen={isOpen} onClose={onClose} email={email} />
          <Heading
            w="100%"
            as="h1"
            mb={12}
            textAlign="center"
            letterSpacing="1.50px"
            fontSize="lg"
            fontWeight="normal"
          >
            {intl.formatMessage({
              id: 'auth_loginlayout_title',
            })}
          </Heading>

          <FieldWrapper
            name="email"
            required
            label={intl.formatMessage({
              id: 'auth_loginform_email',
            })}
            as={(props) => <TextInput type="email" {...props} />}
          />
          <FieldWrapper
            name="password"
            required
            label={intl.formatMessage({
              id: 'auth_loginform_password',
            })}
            as={(props) => (
              <>
                <TextInput type="password" {...props} />
                <Text
                  w="100%"
                  onClick={() =>
                    navigate(
                      currentLocale === 'pt-BR'
                        ? '/auth/recuperar/iniciar'
                        : '/auth/recover/initiate',
                    )
                  }
                  cursor="pointer"
                  textAlign="right"
                  pt={[2, 2, 1, 1]}
                  _hover={{ opacity: 0.8 }}
                  fontSize={['xs', 'xs', 'sm', 'sm']}
                >
                  {intl.formatMessage({
                    id: 'auth_forgot_password',
                  })}
                </Text>{' '}
              </>
            )}
          />

          <VStack alignItems="center" justifyContent="space-between">
            <Box w="70%">
              <Button
                w="100%"
                isLoading={isLoggingIn}
                type="submit"
                variant="@primaryGradient"
                mb={4}
              >
                {intl.formatMessage({
                  id: 'auth_loginform_button',
                })}
              </Button>
            </Box>

            <Stack alignItems="center" direction={['column', 'column', 'row', 'row']}>
              <Text fontSize="sm">
                {intl.formatMessage({
                  id: 'auth_dont_have_account',
                })}
              </Text>
              <Text
                as={Link}
                _hover={{ textDecoration: 'none', opacity: 0.8 }}
                to={currentLocale === 'pt-BR' ? '../cadastro' : '../register'}
                fontWeight="bold"
              >
                {intl.formatMessage({
                  id: 'auth_dont_have_account_create',
                })}
              </Text>
            </Stack>
          </VStack>
        </LC.Vertical>
      )}
    </Form>
  );
};
