import { Box, Container, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import bgDetails1 from '@/assets/bg-details-1.png';
import { LogoAllWhite } from '@/components/common/Logo';
import { Head } from '@/components/Head';
import * as LC from '@/components/LC';

type LoginLayoutProps = {
  children: ReactNode;
};

export const LoginLayout = ({ children }: LoginLayoutProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <Head
        title={intl.formatMessage({
          id: 'auth_loginlayout_title',
        })}
      />
      <Box
        bgImage={bgDetails1}
        bgSize="cover"
        bgPosition="top"
        minH="100vh"
        _before={{
          content: '""',
          w: '100%',
          h: '100%',
          pos: 'fixed',
          right: '0',
          top: '0',
          bg: 'brandmatte',
          bgAttachment: 'fixed',
          zIndex: '-1',
        }}
        py={{ base: 7, md: 0 }}
        px={[0, 0, 5, 5]}
      >
        <Container maxW="container.lg" minH="100vh">
          <Flex minH="100vh" alignItems="center" direction={{ base: 'column', md: 'row' }}>
            <LC.Vertical flex={1} alignItems="center" mb={[4, 4, 0]}>
              <LogoAllWhite
                cursor="pointer"
                onClick={() => navigate('/')}
                maxW={['200px', '300px', 'md']}
              />
            </LC.Vertical>
            <LC.Vertical
              color="white"
              ml={[0, 0, 12]}
              flex={1}
              bg="#62589ddc"
              p={6}
              py={10}
              borderRadius={26}
              alignItems="center"
            >
              <Box w={['100%', '90%', '80%']}>{children}</Box>
            </LC.Vertical>
          </Flex>
        </Container>
      </Box>
    </>
  );
};
