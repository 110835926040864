import { LOCALES } from './locales';
import { enUSMessages, ptBRMessages } from './messages';

export const messages = {
  [LOCALES.ENGLISH]: enUSMessages,
  [LOCALES.PORTUGUESE]: ptBRMessages,
};

export * from './context';
export * from './components/LocaleSwitcher';
