import { InitiateForm } from '../components/RecoverForms';
import { RecoverLayout } from '../components/RecoverLayout';

export const InitiateRecover = () => {
  return (
    <RecoverLayout>
      <InitiateForm />
    </RecoverLayout>
  );
};
