import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ConfirmEmail } from './ConfirmEmail';
import { ResetPassword } from './ResetPassword';

function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

const modeTypes = 'resetPassword' || 'verifyEmail';

export const Auth = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const mode = query.get('mode');
  const oobCode = query.get('oobCode');

  useEffect(() => {
    if (!mode || !oobCode) navigate('/login');
    if (!mode?.includes(modeTypes)) navigate('/login');
  }, [mode, navigate, oobCode]);

  if (mode === 'verifyEmail') return <ConfirmEmail oobCode={oobCode} />;

  if (mode === 'resetPassword') return <ResetPassword oobCode={oobCode} />;

  return null;
};
