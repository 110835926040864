import create from 'zustand';
import { persist } from 'zustand/middleware';

import { Locales } from '@/i18n/types';

type LocaleState = {
  locale: Locales;
  setLocale: (locale: Locales) => void;
};

export const useLocaleStore = create<LocaleState>(
  persist(
    (set) => ({
      locale: 'pt-BR',
      setLocale: (locale) =>
        set({
          locale,
        }),
    }),
    {
      name: '_clapme_user_locale',
    },
  ),
);
