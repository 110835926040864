import { Flex } from '@chakra-ui/layout';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

// import { Notifications } from '@/components/Notifications/Notifications';
import { ThemeColorProvider } from '../ThemeColorProvider';

import { Spinner } from '@/components/common/Spinner';
import { APP_NAME } from '@/config';
import { ErrorBoundary } from '@/errors';
import { IntlProvider } from '@/i18n';
import { AuthProvider } from '@/lib/authentication';
import { queryClient } from '@/lib/react-query';
import { useLocaleStore } from '@/store';
import { theme, Fonts } from '@/styles';

import 'react-lazy-load-image-component/src/effects/blur.css';

dayjs.extend(utc);
dayjs.extend(timezone);
SwiperCore.use([Navigation, Pagination, A11y]);

type AppProviderProps = {
  children: React.ReactNode;
};

const locales = {
  ptBR: import('dayjs/locale/pt-br'),
  enUS: import('dayjs/locale/en'),
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const { locale } = useLocaleStore();

  const setDayJsLocale = React.useCallback((language) => {
    const languageFormatted = language.replace('-', '').trim();

    locales[languageFormatted].then(() => {
      const altLang = languageFormatted === 'ptBR' ? 'pt-br' : 'en';
      dayjs.locale(altLang);
    });
  }, []);

  React.useEffect(() => {
    setDayJsLocale(locale);
  }, [locale, setDayJsLocale]);

  return (
    <React.Suspense
      fallback={
        <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      }
    >
      <HelmetProvider>
        <Helmet>
          <title>{APP_NAME}</title>
        </Helmet>
        <ChakraProvider
          theme={{
            ...theme,
          }}
        >
          <IntlProvider>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <Fonts />
            <ErrorBoundary>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools position="bottom-left" />
                {/* <Notifications /> */}
                <AuthProvider>
                  <Router>
                    <ThemeColorProvider>{children}</ThemeColorProvider>
                  </Router>
                </AuthProvider>
              </QueryClientProvider>
            </ErrorBoundary>
          </IntlProvider>
        </ChakraProvider>
      </HelmetProvider>
    </React.Suspense>
  );
};
