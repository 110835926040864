export const enUSMessages = {
  // locale switcher
  localeswticher_label: 'Language',

  // sidebar
  sidebar_home_title: 'Home',
  sidebar_playlists_title: 'Playlists',
  sidebar_discover_title: 'Discover',
  sidebar_discover_route: '/discover',
  sidebar_profile_title: 'Profile',
  sidebar_profile_route: '/profile',
  sidebar_users_title: 'Users',
  sidebar_users_route: '/users',
  sidebar_login_title: 'Login',
  sidebar_logout_title: 'Logout',

  // auth
  auth_loginlayout_title: 'Enter the platform',
  auth_registerlayout_title: 'Create an account',

  auth_loginform_email: 'Email',
  auth_loginform_password: 'Password',
  auth_loginform_button: 'Login',
  auth_loginform_button_facebook: 'Sign with Facebook',
  auth_loginform_button_google: 'Sign with Google',
  auth_loginform_forgotpass: 'Forgot your password?',
  auth_loginform_register: 'Create an account',
  auth_loginform_social: 'Login with',
  auth_forgot_password: 'Forgot your password?',
  auth_dont_have_account: `Dont have an account?`,
  auth_dont_have_account_create: 'Create an account',
  auth_have_account: `Have an account?`,
  auth_have_account_login: 'Login',
  auth_login_successfeedback: 'Success on login.',

  auth_registerform_name: 'Full name',
  auth_registerform_email: 'Email',
  auth_registerform_phone: 'Phone',
  auth_registerform_password: 'Password',
  auth_registerform_confirmpassword: 'Confirm password',
  auth_registerform_button: 'Create account',
  auth_registerform_buttonback: 'Back to login',

  auth_register_successfeedback: 'Account created with success.',

  // home
  home_highlightedevents_title: 'Featured',
  home_nextevents_title: 'Next events',
  home_morerelevants_title: 'Most relevants',
  home_suggestedplaylists_title: 'Suggested playlists',

  // discover
  discover_eventstabs_title: 'Events',
  discover_eventstabs_allevents: 'All events',
  discover_eventstabs_favorites: 'Favorited events',
  discover_eventstabs_attend: 'Participated events',
  discover_eventstabs_myevents: 'My events',
  discover_stagestabs_title: 'Stages',
  discover_stagestabs_allstages: 'All stages',
  discover_stagestabs_morerelevants: 'Most relevants',
  discover_stagestabs_subscribed: 'Subscribed',
  discover_stagestabs_owner: 'Owner',
  discover_eventstabs_not_found: 'No events were found',
  discover_stagestabs_not_found: 'No stages were found',
  discover_stagetabs_manage: 'Manage',
  discover_stagetabs_subscribe: 'Subscribe',
  discover_stagetabs_subscribed: 'Subscribed',

  // event
  event_route: '/event',

  events_eventcard_live: 'live',
  events_eventcard_timeago: '30 minutes ago',
  events_eventcard_whenlabel: 'When',
  events_eventcard_stagelabel: 'Stage',
  events_eventcard_pricelabel: 'Price',
  events_eventcard_pricefree: 'Free',
  events_eventcard_participate: 'Participate',
  events_eventbanner_dayslabel: 'days',
  events_eventcard_sharelabel: 'Share',
  events_eventcard_savelabel: 'Save',
  events_eventbanner_daysagolabel: 'days ago',
  events_futureevent_participate: 'Participate in the event',
  events_futureevent_trailer: 'Trailer',
  events_futureevent_schedule: 'Schedule of the event',
  events_futureevent_schedule_info_message: 'The schedule has not been added yet',
  events_futureevent_suggestedtitle: 'Events that you might like',
  events_futureevent_seedetails: 'See details',
  events_futureevent_watchevent: 'Watch event',
  events_createevent_title: 'Create event',
  events_createstage_title: 'Create stage',
  events_happening_now_product_title: 'Live shopping',
  events_happening_now_edit: 'Edit',
  events_happening_now_reports: 'Reports',
  events_happening_now_like: 'Like',
  events_happening_now_share: 'Share',
  events_happening_now_send_chat: 'Send a message',
  events_happening_now_send_chat_button: 'Send',
  events_createevent_form_title_detail: 'Event detail',
  events_createevent_form_name: 'Name',
  events_createevent_form_startdate: 'Start date',
  events_createevent_form_enddate: 'End date',
  events_createevent_form_timezone: 'Timezone',
  events_createevent_form_synopsis: 'Synopsis',
  events_createevent_form_add_profile_picture: 'Add profile picture',
  events_createevent_form_add_wallpaper_picture: 'Add wallpaper picture',
  events_createevent_form_add_thumbnail_picture: 'Add thumbnail picture',
  events_createevent_form_add_trailer: 'Add trailer',
  events_createevent_form_title_event_schedule: 'Event schedule',
  events_createevent_form_add_event_schedule: 'Add schedule',
  events_createevent_form_add_event_schedule_active: 'Active',
  events_createevent_form_add_event_schedule_disabled: 'Disabled',
  events_createevent_form_add_event_schedule_add: 'Add',
  events_createevent_form_add_event_schedule_hour: 'Hour',
  events_createevent_form_add_event_schedule_title: 'Title',
  events_createevent_form_title_event_view: 'Event view',
  events_createevent_form_who_has_access: 'Who has access',
  events_createevent_form_who_has_access_public: 'Public',
  events_createevent_form_who_has_access_not_listed: 'Not listed',
  events_createevent_form_who_has_access_not_listed_link: 'Access link (click to copy)',
  events_createevent_form_title_entrance: 'Entrance',
  events_createevent_form_event_type: 'Event type',
  events_createevent_form_event_type_free: 'Free event',
  events_createevent_form_event_type_paid: 'Paid event',
  events_createevent_form_title_video_player: 'Video player',

  // stage

  stages_stagepage_event_button: 'Event',
  stages_stagepage_category_button: 'Categories',
  stages_stagepage_feed_button: 'Feed',
  stages_stagepage_products: 'My products',
  stages_stagepage_on_air: 'On air',
  stages_stagepage_next_events: 'Next events',
  stages_stagepage_unavailable: 'Unavailable',
  stages_stagepage_subscribed: 'Subscribed',
  stages_stagepage_subscribe: 'Subscribe',
  stages_stagepage_event_not_created: 'This stage has not yet created an event',

  // profile

  profile_card_plan: 'Plan',
  profile_card_telephone: 'Telephone',
  profile_card_address: 'Address',
  profile_card_payment_status: 'Payment status',
  profile_card_payment_method: 'Payment method',
  profile_tab_title: 'My profile',
  profile_tab_event_button: 'Create event',
  profile_tab_stage_button: 'Create stage',
  profile_tab_invitation_button: 'Create invitation',
  profile_stage_card_title: 'My stages',
  profile_event_card_title: 'My events',
  profile_stage_next_events_title: 'Next events',
  profile_stage_info_message: `You don't have any stages`,
  profile_event_info_message: `You don't have any events`,
  profile_stage_subscribers_title: 'Subscribed stages',
  profile_stage_subscribed_message: 'You are not subscribed to any stage',
  profile_stage_subscriber: 'subscriber',
  profile_stage_subscribers: 'subscribers',
  profile_stage_purchases: 'Purchases',
  profile_purchases_table_purchase: 'Shopping',
  profile_purchases_table_date: 'Date',
  profile_purchases_table_price: 'Price',
};
