import { Box, Container } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import bgDetails1 from '@/assets/bg-details-1.png';
import { Logo } from '@/components/common/Logo';
import { Head } from '@/components/Head';
import * as LC from '@/components/LC';

type RegisterLayoutProps = {
  children: ReactNode;
};

export const RecoverLayout = ({ children }: RegisterLayoutProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <Head
        title={intl.formatMessage({
          id: 'auth_registerlayout_title',
        })}
      />
      <Box
        bgImage={bgDetails1}
        bgSize="cover"
        bgPosition="top"
        minH="100vh"
        _before={{
          content: '""',
          w: '100%',
          h: '100%',
          pos: 'fixed',
          right: '0',
          top: '0',
          bg: 'brandmatte',
          bgAttachment: 'fixed',
          zIndex: '-1',
        }}
        pt={-2}
      >
        <Container maxW="container.sm">
          <LC.Vertical alignItems="center">
            <LC.Vertical flex={1} center pt="10" pb="16">
              <Logo
                cursor="pointer"
                onClick={() => navigate('/')}
                maxW={['300px', '350px', 'sm']}
              />
            </LC.Vertical>
            {children}
          </LC.Vertical>
        </Container>
      </Box>
    </>
  );
};
