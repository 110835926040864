import { useRadioGroup, RadioProps, Stack } from '@chakra-ui/react';
import { FieldProps } from 'formik';

import { RadioCard } from './RadioCard';

export interface CustomRadioProps extends Omit<RadioProps, 'form'>, FieldProps {
  options: string[];
}

export const CustomRadio = ({ field, form, options, ...restProps }: CustomRadioProps) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const { meta } = { ...restProps };

  const $setFieldValue = (value: string) => {
    setFieldValue(name, value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    value: value,
    onChange: $setFieldValue,
  });

  const group = getRootProps();

  return (
    <Stack
      direction={['column', 'column', 'row', 'row']}
      spacing={10}
      {...group}
      {...(field as any)}
      {...restProps}
    >
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard error={meta.error} key={value} {...radio}>
            {value}
          </RadioCard>
        );
      })}
    </Stack>
  );
};
