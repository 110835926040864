import { PasswordForm } from '../components/RecoverForms';
import { RecoverLayout } from '../components/RecoverLayout';

type ResetPasswordProps = {
  oobCode: string | null;
};

export const ResetPassword = ({ oobCode }: ResetPasswordProps) => {
  return (
    <RecoverLayout>
      <PasswordForm oobCode={oobCode} />
    </RecoverLayout>
  );
};
