import { Box, Container, Heading, Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import bgDetails1 from '@/assets/bg-details-1.png';
import { Logo } from '@/components/common/Logo';
import { Head } from '@/components/Head';
import * as LC from '@/components/LC';

type RegisterLayoutProps = {
  children: ReactNode;
};

export const RegisterLayout = ({ children }: RegisterLayoutProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <>
      <Head
        title={intl.formatMessage({
          id: 'auth_registerlayout_title',
        })}
      />
      <Box
        bgImage={bgDetails1}
        bgSize="cover"
        bgPosition="top"
        _before={{
          content: '""',
          w: '100%',
          h: '100%',
          pos: 'fixed',
          right: '0',
          top: '0',
          bg: 'brandSecondaryLight',
          bgAttachment: 'fixed',
          zIndex: '-1',
        }}
        pt={-2}
      >
        <Container maxW="container.sm" minH="100vh">
          <LC.Vertical minH="100vh" alignItems="center" pb={8}>
            <LC.Vertical flex={1} center mb={[4, 4, 0]}>
              <Logo
                cursor="pointer"
                onClick={() => navigate('/')}
                maxW={['300px', '350px', 'md']}
              />
            </LC.Vertical>
            <Stack
              w="100%"
              color="white"
              bg="#62589ddc"
              boxShadow="md"
              px={6}
              borderRadius={10}
              justifyContent="center"
              alignItems="center"
            >
              <Heading
                w="100%"
                as="h1"
                my={6}
                textAlign="center"
                letterSpacing="1.50px"
                fontSize="lg"
                fontWeight="normal"
              >
                {intl.formatMessage({
                  id: 'auth_registerlayout_title',
                })}
              </Heading>

              <Box w="full" px={4}>
                {children}
              </Box>
            </Stack>
          </LC.Vertical>
        </Container>
      </Box>
    </>
  );
};
