import { Input } from '@chakra-ui/react';
import { FieldProps } from 'formik';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';

import { useLocale } from '@/i18n';

export interface NumberInputFormatProps extends Omit<NumberFormatPropsBase, 'form'>, FieldProps {
  withCurrencyFormatter?: boolean;
}

export const NumberInputFormat = ({
  field,
  form,
  prefix,
  suffix,
  withCurrencyFormatter = true,
  ...restProps
}: NumberInputFormatProps) => {
  const { currentLocale } = useLocale();
  const { name, value } = field;
  const { setFieldValue } = form;
  const currentCurrency = currentLocale ? (currentLocale == 'pt-BR' ? 'BRL' : 'USD') : 'BRl';

  const $setFieldValue = (value: any) => setFieldValue(name, value);

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat(currentLocale || 'pt-BR', {
      style: 'currency',
      currency: currentCurrency,
    }).format(value / 100);

    return `${amount}`;
  }

  return (
    <NumberFormat
      {...field}
      value={value}
      customInput={Input}
      allowNegative={false}
      onValueChange={(value) => $setFieldValue(value)}
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale
      format={withCurrencyFormatter ? currencyFormatter : undefined}
      prefix={prefix}
      suffix={suffix}
      {...restProps}
    />
  );
};
