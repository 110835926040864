import { FieldProps } from 'formik';
import Select, { Props } from 'react-select';

import { styles } from '../../styles';

interface Option {
  value: any;
  label?: string;
}

interface SearchSelectFieldProps extends Omit<Props, 'form'>, FieldProps {
  value?: string;
  options?: Option[];
}

export const SearchSelect = ({
  field,
  form,
  options,
  isMulti,
  ...restProps
}: SearchSelectFieldProps) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const values: any = options ? options.find((option) => option.value === value) : '';

  const onChange = (option: Option | Option[] | unknown) => {
    setFieldValue(
      name,
      isMulti
        ? option && (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value,
    );
  };

  return (
    <Select
      value={values}
      styles={styles}
      options={options}
      isMulti={isMulti}
      onChange={onChange}
      placeholder="Selecione..."
      noOptionsMessage={({ inputValue }) => (!inputValue ? 'Sem opções' : 'Sem resultados')}
      isOptionDisabled={() => (isMulti ? value.length >= 5 : false)}
      {...restProps}
    />
  );
};
