import { css, Global } from '@emotion/react';

const globalFonts = css`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

  @font-face {
    font-family: 'Pilat';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('/fonts/PilatExtended-Black.woff2') format('woff2'),
      url('/fonts/PilatExtended-Black.woff') format('woff'),
      url('/fonts/PilatExtended-Black.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Pilat';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('/fonts/PilatExtended-Heavy.woff2') format('woff2'),
      url('/fonts/PilatExtended-Heavy.woff') format('woff'),
      url('/fonts/PilatExtended-Heavy.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Pilat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/PilatExtended-Bold.woff2') format('woff2'),
      url('/fonts/PilatExtended-Bold.woff') format('woff'),
      url('/fonts/PilatExtended-Bold.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Pilat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/PilatExtended-Regular.woff2') format('woff2'),
      url('/fonts/PilatExtended-Regular.woff') format('woff'),
      url('/fonts/PilatExtended-Regular.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`;

export const Fonts = () => <Global styles={globalFonts} />;
